<template>
  <div>
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 16px">
            <router-link :to="{ path: '/carinfo', query: { id: carId } }">车源信息</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 16px">
            车源详情
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>

    <div class="GoodsDetail">
      <div class="content">
        <div class="goodsInfo">
          <el-image class="infoLeft" :src="carInfo.CAR_IMG_PATH"></el-image>
          <div class="infoRight">
            <div class="infoBox">
              <h3 class="name">
                {{ (carInfo.CAR_BRAND || '') + " " + (carInfo.CAR_TYPE || '') + " " + (carInfo.CAR_NO || '') }}</h3>
            </div>
            <div class="infoBox">
              <h3 class="price">{{ '价格面议' }}</h3>
            </div>
            <div class="infoBox">
              <span>公司(个人)：</span>
              <span>{{ carInfo.LINK_UNIT }}</span>
            </div>
            <div class="infoBox">
              <span>联系人：</span>
              <span>{{ carInfo.USER_NAME }}</span>
            </div>
            <div class="infoBox">
              <span>电话：</span>
              <span>{{ carInfo.PHONE }}</span>
            </div>
            <div class="infoBox">
              <span>轮廓：</span>
              <span>{{
                  '长:' + carInfo.BOARD_LENGTH + " 米, 宽:" + carInfo.BOARD_WIDTH + " 米, 高:" + carInfo.CARRIAGE_HEIGHT + ' 米'
                }}</span>
            </div>
            <div class="infoBox">
              <span>最大吨位：</span>
              <span>{{ carInfo.MAX_TONNAGE + ' 吨' }}</span>
            </div>
            <!--          <div class="infoBox">-->
            <!--            <span>最大容积：</span>-->
            <!--            <span>{{carInfo.MAX_VOLUME===0?'未知':carInfo.MAX_VOLUME+' m³'}}</span>-->
            <!--          </div>-->
            <div class="infoBox">
              <span>地址：</span>
<!--              <span>{{ carInfo.AREA_NAME }}</span>-->
              <span>{{ carInfo.ADDRESS }}</span>
            </div>
            <button class="buyBtn" @click="call">立即联系</button>
          </div>
        </div>
      </div>
      <el-dialog
          title="立即联系"
          :visible.sync="dialogVisible"
          width="30%">
        <span>请直接拨打 <a :href="'tel:'+carInfo.PHONE">{{ contactTel }}</a></span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'GoodsDetail',
  data() {
    return {
      contactTel:'',
      dialogVisible: false,
      defaultImg: require('/static/default-car.jpg'),
      carId: this.$route.params.id,
      carInfo: {
        CAR_BRAND: '',
        CAR_TYPE: '',
        CAR_IMG_PATH: '',
        CAR_NO: '',
        MAX_TONNAGE: '',
        BOARD_LENGTH: '',
        BOARD_WIDTH: '',
        CARRIAGE_HEIGHT: '',
        MAX_VOLUME: '',
        LINK_UNIT: '',
        USER_NAME: '',
        PHONE: '',
        AREA_NAME: '',
        ADDRESS: ''
      }
    }
  },
  created() {
    this.getSupplyDetail();
    this.getContactInfo();
  },
  methods: {
    call() {
      this.dialogVisible = true;
    },
    getSupplyDetail() {
      axios.get(window.apiUrl + "/api/common/carinfo/" + this.carId).then(res => {
        this.carInfo = res.data.data;
        if(!this.carInfo.CAR_IMG_PATH){
          this.carInfo.CAR_IMG_PATH=this.defaultImg;
        }
      });
    },
    getContactInfo(){
      axios.get(window.apiUrl + "/api/common/getSysconfig?configKey=CONTACT_US").then(res => {
        let result = JSON.parse(res.data.data);
        this.contactTel = result.contactTel;
      });
    }

  }
}
</script>

<style scoped lang="scss">
.small_line {
  background: #ddd;
  height: 1px;
}
.GoodsDetail {
  padding: 50px 100px;

  .content {
    width: 100%;
    padding-top: 20px;

    .goodsInfo {
      width: 100%;
      overflow: hidden;

      .infoLeft {
        display: inline-block;
        width: 40%;
        height: 515px;
        float: left;
      }

      .infoRight {
        display: inline-block;
        float: right;
        width: 50%;

        .infoBox {
          margin-bottom: 30px;

          .name {
            font-size: 20px;
          }

          p {
            font-size: 15px;
          }

          .price {
            font-size: 35px;
            color: red;
          }

          span:first-child {
            font-size: 13px;
            display: inline-block;
            width: 80px;
          }

          span:last-child {
            font-size: 13px;
            display: inline-block;
            width: 250px;
          }

          .tips {
            width: auto;
            margin: 0 20px 0 5px;
          }

          .NumberInput {
            display: inline-block;
            vertical-align: middle;
          }
        }

        button {
          background-color: #b4a078;
          width: 170px;
          height: 50px;
          color: white;
          border: none;
          font-size: 19px;
          margin-right: 20px;
          margin-top: 10px;

          &:hover {
            opacity: 0.8;
          }
        }

        .buyBtn {
          border: 1px solid #b4a078;
          color: #b4a078;
          background-color: #f5f3ef;
        }
      }
    }
  }

  .ban {
    user-select: none;
    cursor: not-allowed;
  }
}
</style>